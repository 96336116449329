import { BREAK_POINTS } from '@aimmo-renew-home/environments/environment.interface';
import { DeviceType } from '@aimmo-renew-home/shared/models/breakpoints.model';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { filterNotNil } from '@falcon/common/utils';
import { BehaviorSubject, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreakpointsService {
  private device: DeviceType;
  private readonly layoutChangesSource = new BehaviorSubject<BreakpointState>(null);

  constructor(private breakpointObserver: BreakpointObserver) {
    this.device = this.getCurrentDeviceByBreakPoints(this.breakpoints);
    this.breakpointObserver.observe(this.breakpoints).pipe(
      tap(state => {
        this.device = this.getCurrentDeviceByBreakPoints(this.breakpoints);
        this.layoutChangesSource.next(state);
      })
    ).subscribe();
  }

  public get layoutChangesEvent$(): Observable<BreakpointState> {
    return this.layoutChangesSource.asObservable().pipe(filterNotNil());
  }

  public get isPortableDevice(): boolean {
    return [
      DeviceType.mobile,
      DeviceType.tablet
    ].includes(this.device);
  }

  public get isDesktopDevice(): boolean {
    return [
      DeviceType.desktop,
      DeviceType.fullSize
    ].includes(this.device);
  }

  private get breakpoints(): string[] {
    return Object.keys(BREAK_POINTS);
  }

  private getCurrentBreakPoint(breakPoints: string[]): string {
    return breakPoints.find(breakPoint => this.breakpointObserver.isMatched(breakPoint));
  }

  private getCurrentDeviceByBreakPoints(breakPoints: string[]): DeviceType {
    const breakPoint = this.getCurrentBreakPoint(breakPoints);
    return BREAK_POINTS[breakPoint];
  }
}
